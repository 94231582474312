import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { ISection, SectionTypes } from '../../types';
import Section from '../Section';
import {
  ContactInfoViewModel,
  ContactSectionViewModel,
} from '../../../../service-page-view-model/contact-section-view-model/contactSectionViewModel';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';
import settingsParams from '../../settingsParams';
import { useAddVisibilityClass } from '../../hooks/useAddVisibilityClass';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';
import { classes, vars } from './Contact.st.css';

type ContactComponentProps = {
  viewModel?: ContactSectionViewModel;
  section: ISection;
};

const Contact: React.FC<ContactComponentProps> = ({ viewModel }) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { shouldBeVisible } = useVisibilityCheck();
  const { addVisibilityClass } = useAddVisibilityClass();

  const contactSectionTitle = () => {
    const contactDetailsTitle =
      settings.get(settingsParams.contactDetailsTitleText) ||
      t('app.settings.sections.contact.default.contact-details-title.label');
    return (
      <SectionTitle title={contactDetailsTitle} dataHook="contact-title" />
    );
  };

  const businessPhoneNumber = () => {
    return shouldBeVisible('businessPhoneNumberVisibility') &&
      viewModel?.phone ? (
      <div
        className={addVisibilityClass(
          '',
          classes.businessPhoneNumberVisibility,
          'businessPhoneNumberVisibility',
        )}
      >
        <SectionText text={viewModel.phone} dataHook="business-phone-number" />
      </div>
    ) : null;
  };

  const businessEmail = () => {
    return shouldBeVisible('businessEmailVisibility') && viewModel?.email ? (
      <div
        className={addVisibilityClass(
          '',
          classes.businessEmailVisibility,
          'businessEmailVisibility',
        )}
      >
        <SectionText text={viewModel.email} dataHook="business-email" />
      </div>
    ) : null;
  };

  const businessAddress = () => {
    return shouldBeVisible('businessAddressVisibility') &&
      viewModel?.address ? (
      <div
        className={addVisibilityClass(
          '',
          classes.businessAddressVisibility,
          'businessAddressVisibility',
        )}
      >
        <SectionText text={viewModel.address} dataHook="business-address" />
      </div>
    ) : null;
  };

  const businessLocationAddress = (address) => {
    return address && shouldBeVisible('businessAddressVisibility') ? (
      <SectionText
        text={address}
        dataHook="location-address"
        className={addVisibilityClass(
          '',
          classes.businessAddressVisibility,
          'businessAddressVisibility',
        )}
      />
    ) : null;
  };

  const businessLocationPhone = (phone) => {
    return shouldBeVisible('businessPhoneNumberVisibility') && phone ? (
      <SectionText
        text={phone}
        dataHook="location-phone-number"
        className={addVisibilityClass(
          '',
          classes.businessPhoneNumberVisibility,
          'businessPhoneNumberVisibility',
        )}
      />
    ) : null;
  };

  const businessLocationEmail = (email) => {
    return shouldBeVisible('businessEmailVisibility') && email ? (
      <SectionText
        text={email}
        dataHook="location-email"
        className={addVisibilityClass(
          '',
          classes.businessEmailVisibility,
          'businessEmailVisibility',
        )}
      />
    ) : null;
  };

  const businessLocationName = (name) => {
    return name && shouldBeVisible('locationNameVisibility') ? (
      <SectionText
        className={addVisibilityClass(
          classes.locationName,
          classes.locationNameVisibility,
          'locationNameVisibility',
        )}
        text={name}
        dataHook="location-name"
      />
    ) : null;
  };

  const businessLocationInfo = (contactInfo: ContactInfoViewModel) => {
    return (
      <li className={classes.businessLocation} key={contactInfo.name}>
        {businessLocationName(contactInfo.name)}
        {businessLocationAddress(contactInfo.address)}
        {businessLocationPhone(contactInfo.phone)}
        {businessLocationEmail(contactInfo.email)}
      </li>
    );
  };

  const multiLocationContacts = () => {
    const contactInfo = viewModel?.contactInfo;
    const numberOfLocations = Number(contactInfo?.length);
    const shouldShowMultiLocation = numberOfLocations > 0;
    const maxNumberOfColumns = Math.min(numberOfLocations, 3);
    return shouldShowMultiLocation ? (
      <Section sectionType={SectionTypes.CONTACT}>
        <div data-hook="contact-details-wrapper">
          {contactSectionTitle()}
          <ul
            className={classes.businessLocations}
            style={{ [vars.maxNumberOfColumns]: maxNumberOfColumns }}
          >
            {contactInfo?.map(businessLocationInfo)}
          </ul>
        </div>
      </Section>
    ) : null;
  };

  return viewModel?.contactInfo?.length ? (
    multiLocationContacts()
  ) : viewModel?.address || viewModel?.email || viewModel?.phone ? (
    <Section sectionType={SectionTypes.CONTACT}>
      <div data-hook="contact-details-wrapper">
        {contactSectionTitle()}
        {businessPhoneNumber()}
        {businessEmail()}
        {businessAddress()}
      </div>
    </Section>
  ) : null;
};

export default Contact;
