import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  Text,
  TextTypography as TYPOGRAPHY,
  Badge,
  BadgePriority as BADGE_PRIORITY,
} from 'wix-ui-tpa/cssVars';
import { st, classes } from './TitleAndTagline.st.css';
import { ISection, MainComponents, SectionTypes } from '../../types';
import Section from '../Section';
import { TitleAndTaglineViewModel } from '../../../../service-page-view-model/title-and-tagline-view-model/titleAndTaglineViewModel';
import { VideoFillXSmall as Camera } from '@wix/wix-ui-icons-common/on-stage/system';
import { useRenderLocation } from '../useRenderLocation';
import { useGlobalAlignment } from '../useGlobalAlignment';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { useAddVisibilityClass } from '../../hooks/useAddVisibilityClass';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';

type TitleAndTaglineProps = {
  viewModel?: TitleAndTaglineViewModel;
  section: ISection;
  className?: string;
};

const TitleAndTagline = ({ viewModel, className }: TitleAndTaglineProps) => {
  const { t } = useTranslation();
  const location = useRenderLocation();
  const globalAlignment = useGlobalAlignment();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const { addVisibilityClass } = useAddVisibilityClass();
  const { shouldBeVisible } = useVisibilityCheck();
  const showOnlineBadge =
    shouldBeVisible('onlineBadgeVisibility') && viewModel?.availableOnline;

  const showServiceTitle = shouldBeVisible('serviceTitleVisibility');
  const showServiceTagline =
    shouldBeVisible('serviceTaglineVisibility') && viewModel?.tagline;

  const content: JSX.Element[] = [];
  if (showOnlineBadge) {
    content.push(
      <Badge
        key="title-and-tagline-badge"
        icon={<Camera />}
        data-hook="title-and-tagline-badge"
        priority={BADGE_PRIORITY.light}
        className={addVisibilityClass(
          classes.badge,
          classes.badgeVisibility,
          'onlineBadgeVisibility',
        )}
      >
        {settings.get(settingsParams.onlineBadgeText) ||
          t('app.settings.sections.titles.default.online-badge.text')}
      </Badge>,
    );
  }
  if (showServiceTitle) {
    const titleHtmlTag =
      location === MainComponents.BODY
        ? settings.get(settingsParams.bodyServiceTitleHtmlTag)
        : settings.get(settingsParams.sidebarServiceTitleHtmlTag);
    const shouldHideTitleFromScreenReader =
      settings.get(settingsParams.headerTitleVisibility) &&
      shouldBeVisible('headerVisibility') &&
      settings.get(settingsParams.headerTitleHtmlTag) ===
        AccessibilityHtmlTags.MainHeader &&
      titleHtmlTag === AccessibilityHtmlTags.MainHeader;
    content.push(
      <div
        data-hook="title-and-tagline-title-wrapper"
        aria-hidden={shouldHideTitleFromScreenReader}
        key="title-and-tagline-title-wrapper"
        className={addVisibilityClass(
          '',
          classes.titleVisibility,
          'serviceTitleVisibility',
        )}
      >
        <Text
          data-hook="title-and-tagline-title"
          className={classes.title}
          typography={TYPOGRAPHY.largeTitle}
          tagName={titleHtmlTag}
        >
          {viewModel?.title}
        </Text>
      </div>,
    );
  }
  if (showServiceTagline) {
    content.push(
      <Text
        key="title-and-tagline-tagline"
        data-hook="title-and-tagline-tagline"
        className={addVisibilityClass(
          classes.tagline,
          classes.taglineVisibility,
          'serviceTaglineVisibility',
        )}
        typography={TYPOGRAPHY.runningText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {viewModel?.tagline}
      </Text>,
    );
  }

  return content.length ? (
    <Section
      sectionType={SectionTypes.TITLE_TAGLINE}
      childrenAlignment={settings.get(settingsParams.titleAndTaglineAlignment)}
    >
      <div
        className={st(
          classes.root,
          {
            alignment:
              settings.get(settingsParams.titleAndTaglineAlignment) ||
              globalAlignment,
            location,
            isMobile,
            isRTL,
          },
          className,
        )}
        data-hook="title-and-tagline-wrapper"
      >
        {content}
      </div>
    </Section>
  ) : null;
};

export default TitleAndTagline;
