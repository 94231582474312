import React from 'react';
import { TimezoneType } from '@wix/bookings-uou-types';

export interface IWidgetEvents {
  navigateToCalendar: (referralInfo?: string) => void;
  changeTimezoneCallback?: (timezoneType: TimezoneType) => void;
  changeLocationCallback?: (locationId: string) => void;
}

export const WidgetEventsContext = React.createContext<IWidgetEvents>({
  navigateToCalendar: (referralInfo) => {},
  changeTimezoneCallback: () => {},
  changeLocationCallback: () => {},
});

export const WidgetEventsConsumer = WidgetEventsContext.Consumer;
export const WidgetEventsProvider = WidgetEventsContext.Provider;

export const useWidgetEvents = () => React.useContext(WidgetEventsContext);
